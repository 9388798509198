import { Box } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
 
const Bestellen = () => {
  return (
    <div className="faq-page">
           <Helmet>
    
    <meta charset="utf-8" />
        <meta name="description" content="Auf unserer Website bieten wir eine benutzerfreundliche Möglichkeit, digitale Vignetten zu bestellen. Befolgen Sie die untenstehenden Schritte, um Ihre Vignetten zu bestellen" />
        <meta name="keywords" content="digitalmaut.at, Austrian vignette, Austrian toll sticker, Austria highway toll, Vignette Austria, Austrian road tax, Toll fee Austria, Autobahnvignette Österreich, Austrian motorway toll, Vignette for Austria, Widerrufsrecht, Vermittlungsdienstleistung, digitalmaut.at, Widerrufsfrist, Vertragsabschluss, Vertrag widerrufen, Europäisches Muster-Widerrufsformular, Zahlungen zurückerstatten, Widerrufsentscheidung" />
        <title>Bestellen, bezahlen und liefern - digitalmaut.at</title>
    <meta name="robots" content="index, follow" />
    <meta name="author" content="Digital Maut" />

   
       </Helmet>
      <Box className='container-lg py-5'>
        <div className="hero-banner">
          <h1>Bestellen, bezahlen und liefern</h1>
        </div>
<Box>
    <h3>Bestellen</h3>
    <p>Auf unserer Website bieten wir eine benutzerfreundliche Möglichkeit, digitale Vignetten zu bestellen. Befolgen Sie die untenstehenden Schritte, um Ihre Vignetten zu bestellen:</p>
<ol style={{ listStyle: 'decimal' }}>
  <li>Gehen Sie zur Antragsseite und wählen Sie das Fahrzeug, mit dem Sie reisen möchten. Wählen Sie das gewünschte Vignette: ein Vignette für 10 Tage, 2 Monate oder ein ganzes Jahr.</li>
  <li>Geben Sie den Gültigkeitszeitraum ein, für den das Vignette gelten soll. Wir berechnen automatisch das Ablaufdatum basierend auf Ihrer gewählten Vignette.</li>
  <li>Geben Sie die Fahrzeugdaten ein, einschließlich des Landes der Zulassung und des Kennzeichens, für das das Vignette gültig sein soll. Vergessen Sie nicht, Ihren Namen und Ihre E-Mail-Adresse anzugeben, damit wir Ihnen die digitalen Vignetten zusenden können.</li>
  <li>Akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen, einschließlich des Widerrufsrechts, und bestätigen Sie, dass Sie die Vignetten sofort erhalten möchten.</li>
  <li>Sobald Sie die Zahlung abgeschlossen haben, wird der Vermittlungsvertrag abgeschlossen. Wir stellen sicher, dass der Vertrag zwischen Ihnen und dem Vignetthersteller abgeschlossen wird. Die digitalen Vignetten werden direkt an Ihre E-Mail-Adresse gesendet.</li>
</ol>
<p>Folgen Sie diesen einfachen Schritten, um Ihre digitalen Vignetten mühelos zu bestellen und zu erhalten.</p>
<h3>bezahlen</h3>
<p>Sie können die Zahlung auf unserer Zahlungsseite über unseren Zahlungsanbieter Stripe vornehmen. Sie können mit den folgenden Zahlungsmethoden bezahlen:</p>
<ul style={{ listStyle: 'disc' }}>
  <li>iDEAL</li>
  <li>Kreditkarte</li>
  <li>Apple Pay</li>
  <li>Bancontact</li>
  <li>SOFORT Banking</li>
  <li>Giropay</li>
  <li>Przelewy24</li>
  <li>EPS</li>
</ul>
<p>Es fallen keine zusätzlichen Gebühren für die Verwendung dieser Zahlungsmethoden an.</p>

<h3>liefern</h3>

<p>Die Vignetten werden Ihnen digital vom entsprechenden Vignettherausgeber des jeweiligen Landes zugesandt. In der Regel erhalten Sie die Vignetten innerhalb von 2 Stunden. Es fallen keine Kosten für den Versand an.</p>

<p>Haben Sie sich für unseren Express-Service entschieden, um eine schnellere Bearbeitungszeit zum Preis von 4,95 € zu erhalten? In diesem Fall können Sie normalerweise mit einer Lieferung innerhalb von 1 Stunde rechnen.</p>
<a href="/" className="btn btn-primary btn-lg">Vignette kaufen</a>

    </Box> 

      </Box>
    </div>
  );
};

export async function getServerSideProps() {
  return {
    props: {}, 
  };
}

export default Bestellen;
