 
import { Box } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

 
const PrivacyPolicy = () => {
  
  return (
    <div>
 <Helmet>
 
  <title>Privacy Policy | digitalmaut.at</title>
  <meta name="keywords" content="österreich vignette, vignette österreich kaufen, österreich vignette kaufen, österreich vignette digital, österreich vignette kosten, österreich vignette 2022, vignette österreich und slowenien, österreich vignette wo kaufen, vignette österreich 2022 preis, vignette österreich anhänger, österreich vignette motorrad, österreich vignette sofort gültig, österreich vignette wohnwagen, österreich vignette wo anbringen, österreich vignette 1 tag, österreich vignette anhänger"/>
  <meta name="description" content="Bei De Man Techniek legen wir großen Wert auf Ihre Privatsphäre. Wir sind vollständig bestrebt, die geltenden Gesetze und Vorschriften zum Datenschutz einzuhalten," />
  <meta name="robots" content="index,follow"/>
  <meta name="author" content="Digitalmaut"/>
  <meta name="publisher" content="De Man Techniek" />

  
  <meta http-equiv="X-UA-Compatible" content="ie=edge" />
</Helmet>

       
  <Box id="fahrzeug" className='rounded-3 bg-light border' style={{ width: '100%', textAlign: 'center', marginBottom: "0", marginTop: "0px", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
     <h1 className='display-5' style={{ width: '100%', textAlign: 'center', zIndex: '1'}} >Privacy Policy</h1>
   </Box> 
  <Box id="fahrzeug" className='rounded-3 container-lg' style={{ width: '100%', maxWidth: '750px',  marginBottom: "0", marginTop: "0px", position: 'relative', marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  
<p>You can download and print the General Terms and Conditions <a href="/images/digitalmaut-de-privacy-policy.pdf" target='_blank'>here</a>.</p>
<p>July 1, 2023</p>
<p><b>Privacy and Cookie Statement by De Man Techniek</b></p>
<p>At De Man Techniek, we value your privacy. We are fully committed to complying with applicable privacy laws and regulations, including the General Data Protection Regulation (GDPR). This means that we:</p>
<p>- <b>clearly define our purposes</b> before processing your personal data, as described in this privacy statement;</p>
<p>- <b>strive to store as little personal data as possible</b> and only collect the data that is necessary for our specific purposes;</p>
<p>- <b>explicitly request your consent</b> when consent is required before processing your personal data;</p>
<p>- <b>take the necessary security measures to protect your personal data</b> and also impose the same requirements on third parties who process personal data on our behalf;</p>
<p>- <b>respect your rights</b>, including the right to access, rectify, or delete your personal data processed by us.</p>
<p>Your data is safe with De Man Techniek, and we will always use this data properly. In this privacy statement, we explain what we do at De Man Techniek with the information we learn about you.</p>
<p>If you have any questions or would like to know exactly what information we store about you, please contact De Man Techniek.</p>
<p><b>We take care of careful processing of your order.</b></p>
<p>When you place an order with us, we use your personal data to process it properly. We may then pass on your personal data to our delivery service to deliver the order to you. We also receive information about your payment from your bank or credit card company. For this purpose, we use:</p>
<ul style={{ listStyle: 'disc' }}>
    <li><p>Name, address, place of residence (contact details)</p></li>
    <li><p>Email address: We use your email address to send you important information about your order, such as order confirmations, shipping information, and, if necessary, updates or notifications regarding your purchase.</p></li>
    <li><p>Payment data: To process your payment, we collect the necessary information such as credit card details, bank account number, or other payment information. This data is only used to complete the payment of your order.</p></li>
    <li><p>IP address: For security and fraud prevention purposes, we may store your IP address. This helps us identify suspicious activities and protect your data.</p></li>
    <li><p>Passport data (date of birth, gender, photo, passport number, etc.)</p></li>
</ul>

<p>We understand that certain data is necessary due to the contract we have with you. It is common for us to collect personal data such as name, address, email address, payment information, and, if applicable, passport data for processing your order. We will retain this data until your order has been processed.</p>
<p>In addition, we are legally obligated to retain certain customer data for a longer period of time due to tax retention requirements. This means that we must keep certain information for a specific period of time to comply with tax laws and regulations. After the retention period has expired, we will securely delete your data.</p>
<p>At De Man Techniek, we highly value the protection of your privacy and will only retain and use your data in accordance with applicable laws. If you have any questions about the retention periods of your personal data, please contact us for more information.</p>
<p><i>Disclosure to Third Parties</i></p>
<p>We have partnerships with specific companies that may receive the personal data provided by you as mentioned above.</p>
<p>In order to process orders, we collaborate with the following partners:</p>
<ul style={{ listStyle: 'disc' }}>
    <li><p>Our payment provider is Stripe.</p></li>
    <li><p>The government or foreign ministry of the country for which you are applying for a visa.</p></li>
</ul>
<p><b>Contact Form</b></p>
<p>Through the contact form, you can ask us questions or submit inquiries. For these purposes, we use the following data from you:</p>
<ul style={{ listStyle: 'disc' }}>
    <li><p>Email address</p></li>
</ul>
<p>We do this based on your explicit consent. We will store this information until we are confident that you are satisfied with our response.</p>
<p><b>Advertising</b></p>
<p>Advertisements are displayed in our webshop. These advertisements are not published by us directly but by advertising agencies we work with. These agencies track whether you find the advertisements appealing and use this information to present you with relevant and appealing advertisements and offers in the future.</p>
<p><b>Disclosure to Other Companies or Institutions</b></p>
<p>We do not disclose your personal data to any other companies or institutions under any circumstances unless it is necessary for providing the aforementioned services. This also includes situations where we are legally obligated to disclose your data, such as when requested by the police in the case of suspicion of a crime. In such cases, we ensure that the data exchange complies with applicable laws and regulations and that your privacy rights are protected.</p>
<p><b>Statistics</b></p>
<p>We compile statistics about the usage of our webshop, with all data being anonymized. This means that the collected data cannot be traced back to individual persons. The statistics are used solely for internal analysis and to improve our webshop.</p>
<p><b>Cookies</b></p>
<p>Our webshop uses cookies. Cookies are small files in which we can store information so that you do not have to enter it again every time. Additionally, cookies allow us to recognize when you revisit our webshop.</p>
<p>The use of cookies enables us to enhance your browsing experience, such as by saving your preferences and customizing the website's content to your interests. It also helps us collect anonymous statistics about the usage of our webshop, allowing us to further optimize it.</p>
<p>You have the option to manage or block the use of cookies in your web browser settings. However, please note that disabling cookies may impact the functionality and user experience of our webshop.</p>


<p><b>Sicherheit</b></p>
<p>We attach great importance to the protection of personal data. We ensure that your data is adequately secured with us. We continuously adapt our security measures and carefully monitor potential risks in order to minimize them.</p>
<p><b>Changes to this Privacy Policy</b></p>
<p>If there are any changes in our online shop, we will also adjust the privacy policy accordingly. Therefore, it is important that you always pay attention to the date at the top of the privacy policy and regularly check for new versions. We will do our best to inform you of any changes so that you stay informed about our privacy policies.</p>
<p><b>Your Rights</b></p>
<p>If you have any questions or would like to know what personal data we have about you, you can contact us at any time. The contact information can be found below.</p>
<p>You have the following rights:</p>
<ul style={{ listStyle: 'disc' }}>
    <li><p>Right to information: You have the right to obtain information about the personal data we collect from you and what we do with it.</p></li>
    <li><p>Right of access: You have the right to access the exact personal data we have about you.</p></li>
    <li><p>Right to rectification: If there are errors or inaccuracies in your personal data, you have the right to have them corrected.</p></li>
    <li><p>Right to erasure: You have the right to have outdated or no longer relevant personal data deleted.</p></li>
    <li><p>Right to data portability: If you wish, you have the right to have your personal data transferred to another responsible party.</p></li>
    <li><p>Right to restriction of processing: You have the right to restrict the processing of your personal data in certain situations, for example, if you dispute the accuracy of the data.</p></li>
    <li><p>Right to object: You have the right to object to certain uses of your personal data, such as direct marketing.</p></li>
</ul>
<p>Please clearly indicate who you are so that we can verify that we are modifying or deleting the correct personal data. It is important to verify your identity to ensure that unauthorized access to your personal data does not occur. Therefore, provide sufficient information and, if necessary, means of identification when contacting us to exercise your rights.</p>
<p>We strive to comply with your request within one month. However, in some cases, this deadline may be extended, for example, if the request is complex or specific legal provisions regarding the rights of data subjects apply. If we need to extend the deadline, we will inform you in a timely manner and provide you with the reason for the delay.</p>
<p><b>Filing a Complaint</b></p>
<p>If you would like to file a complaint about the use of your personal data, you can send an email to info@digitalmaut.at. We will process your complaint internally and keep you informed about the progress and actions taken. We take every complaint seriously and strive to resolve it satisfactorily.</p>
<p>If you believe that we have not adequately responded to your complaint or if you are dissatisfied with our handling, you have the right to file a complaint with the data protection authority. In Germany, this is the Datenschutzbehörde. You can contact the data protection authority and submit your complaint to them. They will review your complaint and take appropriate action if necessary.</p>
<p><b>Contact Information</b></p>
<p>De Man Techniek<br />
    Jacob Schorerlaan 1<br />
    (no visitor address)<br />
</p>
<p>2525 ZP Den Haag<br />
    Email address: info@digitalmaut.at<br />
    M: +31(0)610277889<br />
</p>
<p>
    KVK: 60532726<br />
    USt-IdNr: NL001537522B07
</p>

   </Box> 
 

 
    </div>
  );
};
 

export default PrivacyPolicy;
