import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Alert, AlertTitle, Button, Badge } from '@mui/material';
import DatePicker from 'react-datepicker';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faLock, faCar, faRoute } from '@fortawesome/free-solid-svg-icons';
 import ImageModal from './routemodal';
 import { useLocation } from 'react-router-dom';
 import { Helmet } from 'react-helmet';
 import { Toast } from 'react-bootstrap';


const stripePromise = loadStripe('pk_live_51N9VO3BryLzMACBzMvoE3tc9de4HRkyry8j9CQmiA4LUs2AgrrcTmGr5VYhps2xkGgPGfY0tsTxB9hGgjixXmqRK00jU1teAJv');

function ProductListMaut() {
  const [showToast, setShowToast] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [expressShipping, setExpressShipping] = useState(null);
  const [countryCode, setCountryCode] = useState('DE');
  const [licensePlate, setLicensePlate] = useState('');
  const [confirmLicensePlate, setConfirmLicensePlate] = useState('');
  const [licensePlateMatch, setLicensePlateMatch] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState('');
  const subtotal = selectedProduct ? selectedProduct.price : 0;
  const shippingCost = expressShipping ? 4.95 : 0;
  const totalPrice = subtotal + shippingCost;
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const [refreshCount, setRefreshCount] = useState(0);
  const [email, setEmail] = useState('');
const [confirmEmail, setConfirmEmail] = useState('');
const [emailMatch, setEmailMatch] = useState(true);
const [selectedRoute, setSelectedRoute] = useState('');





  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
    setEmailMatch(
      enteredEmail.toLowerCase() === confirmEmail.toLowerCase()
    );
  };
  
  const handleConfirmEmailChange = (event) => {
    const enteredConfirmEmail = event.target.value;
    setConfirmEmail(enteredConfirmEmail);
    setEmailMatch(
      email.toLowerCase() === enteredConfirmEmail.toLowerCase()
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount(prevCount => prevCount + 1);
    }, 1000); // Refresh every second

    return () => {
      clearInterval(interval);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  
  const handleVehicleSelection = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const filteredProducts = products.filter(product => product.vehicle === selectedVehicle);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://digitalmautapi.onrender.com/other-products');
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCheckout = async () => {
    // Clear any previous errors
    setFormErrors([]);

    const stripe = await stripePromise;
    const name = document.getElementById('nameInput').value;
    const email = document.getElementById('emailInput').value;

    try {
      // Create a customer object in Stripe
      const customerResponse = await fetch('https://digitalmautapi.onrender.com/create-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          licensePlate,
          countryCode,
          selectedDate,
          selectedVehicle,
          selectedProduct, // Include the selectedProduct in the request body
        }),
      });

      const customerData = await customerResponse.json();

      // Calculate the total price
      let totalPrice = selectedProduct.price;
      if (expressShipping) {
        totalPrice += 4.95;
      }

      // Create a new Checkout Session
      const response = await fetch('https://digitalmautapi.onrender.com/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: totalPrice * 100, // convert price to cents (Stripe expects the amount in the smallest currency unit)
          currency: 'EUR', // replace with your desired currency code
          description: selectedProduct.name, // replace with your product description
          customerId: customerData.customerId, // pass the customer ID to associate the payment with the customer
        }),
      });
      const session = await response.json();

      // Redirect to the Checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId, // use the correct property name from the server response
      });

      if (result.error) {
        console.error('Error redirecting to Checkout:', result.error);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };


  // Get the current URL
var url = window.location.href;

// Remove the URL parameters
var cleanUrl = url.split('?')[0];

// Update the browser URL without refreshing the page
window.history.replaceState(null, null, cleanUrl);

const handleProductSelection = (product) => {
  setSelectedProduct(product);
  setSelectedProductName(product.name);
};

const handleLicensePlateChange = (e) => {
  const enteredLicensePlate = e.target.value;
  setLicensePlate(e.target.value);
  setLicensePlateMatch(enteredLicensePlate.toLowerCase() === confirmLicensePlate.toLowerCase());
  setInputError(false);
};

const handleConfirmLicensePlateChange = (e) => {
  const enteredConfirmLicensePlate = e.target.value;
  setConfirmLicensePlate(enteredConfirmLicensePlate);
  setLicensePlateMatch(licensePlate.toLowerCase() === enteredConfirmLicensePlate.toLowerCase());
  setInputError(false);
};

const getCountryCodeColor = (countryCode) => {
  // Add image mappings for each country code
  switch (countryCode) {
     
    default:
      return 'license-plate-default'; // Replace 'license-plate-default' with the CSS class for the default license plate image
  }
};
  
  
// useEffect to control the visibility of the toast
useEffect(() => {
  setShowToast(formErrors.length > 0);
}, [formErrors]);
  

const handleFormSubmit = async () => {
  // Clear any previous form errors
  setFormErrors([]);

  // Perform the form validation
  const errors = [];

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email || !emailRegex.test(email)) {
    errors.push('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
  }

  if (!confirmEmail || email.toLowerCase() !== confirmEmail.toLowerCase()) {
    errors.push('Die E-Mail-Adressen stimmen nicht überein. Bitte versuchen Sie es erneut.');
  }

  if (!selectedProduct) {
    errors.push('Bitte wählen Sie eine Vignette aus.');
  }

  if (!licensePlate) {
    errors.push('Bitte geben Sie ein Kennzeichen ein.');
  }

  if (!confirmLicensePlate) {
    errors.push('Bitte bestätigen Sie das Kennzeichen.');
  }

  if (!countryCode) {
    errors.push('Bitte wählen Sie einen Ländercode für Ihr Kennzeichen aus.');
  }

  if (licensePlate.toLowerCase() !== confirmLicensePlate.toLowerCase()) {
    errors.push('Die Kennzeichen stimmen nicht überein. Bitte versuchen Sie es erneut.');
    setLicensePlateMatch(false);
  }

  if (errors.length > 0) {
    setFormErrors(errors);
    return;
  }

  // Set loading state to true
  setIsLoading(true);

  // Simulate an asynchronous operation (e.g., an API call) using setTimeout
  setTimeout(() => {
    // Reset loading state
    setIsLoading(false);

    // Perform the actual form submission logic here
    handleCheckout();
  }, 1000); // Adjust the timeout duration as needed
};

const handleExpressShippingChange = (e) => {
  setExpressShipping(e.target.checked);
};

  function formatDate(date) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('de-DE', options);
  }

// Function to calculate the validity end date based on the selected date and product ID
function calculateValidityEndDate(selectedDate, selectedProduct) {
  const endDate = new Date(selectedDate);
  
  endDate.setDate(endDate.getDate() + 365); // Add 9 days to the end date for all product IDs
  
  return formatDate(endDate);
}


  

const CountryCode = [
  {
    value: 'AT',
    label: 'Österreich',
    licensePlate: 'license-plate',
    licenseValue: 'A',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen lt. Zulassungsschein bzw. Kennzeichentafel ein. Anstelle des Wappens verwenden Sie bitte einen Bindestrich.',
    'data-placeholdertext': 'W-12345X'
  },
  {
    value: 'DE',
    label: 'Deutschland',
    licensePlate: 'license-plate',
    licenseValue: 'D',
    contentValue: 'Anstelle der Plaketten verwenden Sie bitte einen Bindestrich. Leerstellen sind nicht einzugeben. Bei einem Elektrofahrzeug geben Sie bitte unbedingt auch das "E" am Ende des Kennzeichens ein.',
    'data-placeholdertext': 'B-AB1234'
  },
  {
    value: 'AL',
    label: 'Albanien',
    licensePlate: 'license-plate',
    licenseValue: 'AL',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'AA123BB'
  },
  {
    value: 'AD',
    label: 'Andorra',
    licensePlate: 'license-plate',
    licenseValue: 'AND',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'L0123'
  },
  {
    value: 'BE',
    label: 'Belgien',
    licensePlate: 'license-plate',
    licenseValue: 'B',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '1ABC123'
  },
  {
    value: 'BA',
    label: 'Bosnien und Herzegowina',
    licensePlate: 'license-plate',
    licenseValue: 'BIH',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'K12A456'
  },
  {
    value: 'BG',
    label: 'Bulgarien',
    licensePlate: 'license-plate',
    licenseValue: 'BG',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'CA1234XY'
  },
  {
    value: 'DK',
    label: 'Dänemark',
    licensePlate: 'license-plate',
    licenseValue: 'DK',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'DD123456'
  },
  {
    value: 'EE',
    label: 'Estland',
    licensePlate: 'license-plate',
    licenseValue: 'EST',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '432ABC'
  },
  {
    value: 'FI',
    label: 'Finnland',
    licensePlate: 'license-plate',
    licenseValue: 'FIN',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'MNO321'
  },
  {
    value: 'FR',
    label: 'Frankreich',
    licensePlate: 'license-plate',
    licenseValue: 'F',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'AA123BB'
  },
  {
    value: 'GR',
    label: 'Griechenland',
    licensePlate: 'license-plate',
    licenseValue: 'GR',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'YXX2345'
  },
  {
    value: 'GB',
    label: 'Großbritannien',
    licensePlate: 'license-plate',
    licenseValue: 'GB',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'LA01ABC'
  },
  {
    value: 'IE',
    label: 'Irland',
    licensePlate: 'license-plate',
    licenseValue: 'IRL',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '17W12345'
  },
  {
    value: 'IS',
    label: 'Island',
    licensePlate: 'license-plate',
    licenseValue: 'IS',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'AF123'
  },
  {
    value: 'IT',
    label: 'Italien',
    licensePlate: 'license-plate',
    licenseValue: 'I',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'AA123CD'
  },
  {
    value: 'XK',
    label: 'Kosovo',
    licensePlate: 'license-plate',
    licenseValue: 'RKS',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '01123AB'
  },
  {
    value: 'HR',
    label: 'Kroatien',
    licensePlate: 'license-plate',
    licenseValue: 'HR',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'ZG123AB'
  },
  {
    value: 'LT',
    label: 'Litauen',
    licensePlate: 'license-plate',
    licenseValue: 'LT',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'BCA987'
  },  
  {
    value: 'LI',
    label: 'Liechtenstein',
    licensePlate: 'license-plate',
    licenseValue: 'FL',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'FL12345'
  },
  {
    value: 'LV',
    label: 'Lettland',
    licensePlate: 'license-plate',
    licenseValue: 'LV',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'FM1234'
  },
  {
    value: 'LU',
    label: 'Luxemburg',
    licensePlate: 'license-plate',
    licenseValue: 'L',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'AB1234'
  },
  {
    value: 'MT',
    label: 'Malta',
    licensePlate: 'license-plate',
    licenseValue: 'M',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'CDE123'
  },
  {
    value: 'MD',
    label: 'Moldawien',
    licensePlate: 'license-plate',
    licenseValue: 'MD',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'ABC012'
  },
  {
    value: 'MC',
    label: 'Monaco',
    licensePlate: 'license-plate',
    licenseValue: 'MC',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '7654'
  },
  {
    value: 'ME',
    label: 'Montenegro',
    licensePlate: 'license-plate',
    licenseValue: 'MNE',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'BDAA234'
  },
  {
    value: 'NL',
    label: 'Niederlande',
    licensePlate: 'license-plate',
    licenseValue: 'NL',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'CD34JK'
  },
  {
    value: 'NMK',
    label: 'Nordmazedonien',
    licensePlate: 'license-plate',
    licenseValue: 'NMK',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'SK2345AB'
  },
  {
    value: 'NO',
    label: 'Norwegen',
    licensePlate: 'license-plate',
    licenseValue: 'N',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'NY6789'
  },
  {
    value: 'PL',
    label: 'Polen',
    licensePlate: 'license-plate',
    licenseValue: 'PL',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'WP012AB'
  },
  {
    value: 'PT',
    label: 'Portugal',
    licensePlate: 'license-plate',
    licenseValue: 'P',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '1221AB'
  },
  {
    value: 'RO',
    label: 'Rumänien',
    licensePlate: 'license-plate',
    licenseValue: 'RO',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'BN11ABC'
  },
  {
    value: 'RU',
    label: 'Russland',
    licensePlate: 'license-plate',
    licenseValue: 'RUS',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'A123AA'
  },
  {
    value: 'SM',
    label: 'San Marino',
    licensePlate: 'license-plate',
    licenseValue: 'RSM',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'A5432'
  },
  {
    value: 'SE',
    label: 'Schweden',
    licensePlate: 'license-plate',
    licenseValue: 'S',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'DCB321'
  },
  {
    value: 'CH',
    label: 'Schweiz',
    licensePlate: 'license-plate',
    licenseValue: 'CH',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'ZH543210'
  },
  {
    value: 'RS',
    label: 'Serbien',
    licensePlate: 'license-plate',
    licenseValue: 'SRB',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'BG012AB'
  },
  {
    value: 'SK',
    label: 'Slowakei',
    licensePlate: 'license-plate',
    licenseValue: 'SK',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'TN345AB'
  },
  {
    value: 'SI',
    label: 'Slowenien',
    licensePlate: 'license-plate',
    licenseValue: 'SLO',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'LJAB123'
  },
  {
    value: 'ES',
    label: 'Spanien',
    licensePlate: 'license-plate',
    licenseValue: 'E',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '5678ABC'
  },
  {
    value: 'CZ',
    label: 'Tschechien',
    licensePlate: 'license-plate',
    licenseValue: 'CZ',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '1AB0123'
  },
  {
    value: 'TR',
    label: 'Türkei',
    licensePlate: 'license-plate',
    licenseValue: 'TR',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '34AB012'
  },
  {
    value: 'UA',
    label: 'Ukraine',
    licensePlate: 'license-plate',
    licenseValue: 'UA',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'AA1234AB'
  },
  {
    value: 'HU',
    label: 'Ungarn',
    licensePlate: 'license-plate',
    licenseValue: 'H',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'BCA123'
  },
  {
    value: 'BY',
    label: 'Weißrussland',
    licensePlate: 'license-plate',
    licenseValue: 'BY',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': '1234AB5'
  },
  {
    value: 'CY',
    label: 'Zypern',
    licensePlate: 'license-plate',
    licenseValue: 'CY',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'ABC123'
  },
  {
    value: 'XX',
    label: 'Sonstige Länder',
    licensePlate: 'license-plate',
    licenseValue: 'XX',
    contentValue: 'Bitte geben Sie Ihr Kennzeichen ohne Leerstellen, Bindestrich und Punkt ein!',
    'data-placeholdertext': 'ABC123'
  }
];

useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedVehicleParam = searchParams.get('selectedVehicle');
    setSelectedVehicle(selectedVehicleParam);
  }, [location.search]);

  return (
<Box className='container-lg'>
<Helmet>
<script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org/",
            "@type": "CreativeWorkSeries",
            "name": "Streckenmaut",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "bestRating": "5",
              "ratingCount": "2604"
            }
          }
          `}
        </script>
<meta name="rating" content="General"></meta>
 <title>Streckenmaut für österreich - digitalmaut.at</title>
<meta name="publisher" content="Digital Maut" />
<meta name="title" content="Streckenmaut für österreich - digitalmaut.at"/>
<meta name="description" content="Planen Sie Ihre Reise durch Österreich stressfrei mit der Streckenmaut von digitalmaut.at. Wählen Sie Ihre Mautgebühren für österreichische Autobahnen"/>
<meta name="keywords" content="streckenmaut österreich, digitale streckenmaut österreich adac, streckenmaut österreich online kaufen, digitale streckenmaut österreich kaufen, streckenmaut österreich a10, österreich digitale streckenmaut flex, streckenmaut österreich app, streckenmaut österreich nach kroatien, streckenmaut österreich wohnmobil, streckenmaut österreich a12, streckenmaut österreich motorrad, streckenmaut österreich sofort gültig, streckenmaut österreich abfrage, österreich streckenmaut anhänger, streckenmaut österreich behinderung, digitale streckenmaut österreich wohnwagen, digitale streckenmaut österreich preis, digitale streckenmaut österreich erfahrungen, digitale streckenmaut österreich motorrad, digitale streckenmaut österreich welche spur, digitale streckenmaut österreich kennzeichen ändern, elektronische streckenmaut österreich, streckenmaut österreich italien, österreich streckenmaut jahreskarte, streckenmaut österreich kroatien, streckenmaut münchen wien, streckenmaut österreich nachträglich, streckenmaut österreich prüfen, streckenmaut österreich routenplaner, streckenmaut österreich tauernautobahn, streckenmaut österreich tunnel, digitale streckenmaut tunnel österreich, österreich streckenmaut und vignette, streckenmaut österreich zusätzlich zur vignette"/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>
<meta name="revisit-after" content="1 days"/>
<meta name="author" content="Digital Maut"/>
<link rel="canonical" href="https://digitalmaut.at/streckenmaut" />


       </Helmet>

 
  
  <Box id="products" className='rounded-3 bg-light border' style={{ marginBottom: "0", marginTop: "80px", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  <h1 className='display-7 w700 p-primary' style={{ textAlign: 'center' }}>Digitale Streckenmaut Österreich</h1>

  <Box style={{ marginBottom: '2rem' }}>
  <Badge style={{ position: 'absolute', left: '-15px', top: '-15px',  background: '#10b981 ', color: 'rgb(255, 255, 255)', padding:'5px 15px', boxShadow: 'hsl(329deg 100% 50% / 50%) 0px 10px 40px -10px' }} className="rounded-3" >Schritt 1</Badge>

    <h2 className='display-7 w700' style={{ textAlign: 'center'}} >Streckenmaut auswählen</h2>
     <Box className='wrap-products-main row g-2 gy-4' style={{ width: '100%' }}>
     <select
  className="form-select"
  value={selectedRoute}
  onChange={(e) => setSelectedRoute(e.target.value)}
>
  <option value="" disabled hidden>
  Streckenmaut auswählen 
  </option>
  <option className='notranslate' value="A 13 Brenner Autobahn">A 13 Brenner Autobahn</option>
  <option className='notranslate' value="A 10 Tauern Autobahn">A 10 Tauern Autobahn</option>
  <option className='notranslate'  value="A 11 Karawanken Autobahn - Fahrtrichtung Süden">
    A 11 Karawanken Autobahn - Fahrtrichtung Süden
  </option>
  <option className='notranslate' value="A 9 Pyhrn Autobahn">A 9 Pyhrn Autobahn</option>
</select>


    </Box>
    <ImageModal />
    </Box>
   
   
    <h2 className='display-7 w700' style={{ textAlign: 'center'}} >Dauer auswählen</h2>
    <Box className='wrap-products-main row g-2 gy-4' style={{ width: '100%' }}>
    {selectedRoute &&
  products
  .filter((product) => product.vehicle === selectedRoute)
  .map((product) => (
        <div className="col-12 col-sm-6 col-md-6 col-lg-4" key={product.id}>
          <label className={selectedProduct === product ? 'selected-product border rounded-3' : 'card-product-2 border rounded-3'}>
            <input type="radio" name="product" onChange={() => handleProductSelection(product)} />
            <div className='wrapper--products'>
              <h4 className='h6'>{product.name}</h4>
              <p className='h3 notranslate'><strong>{product.price.toLocaleString('de-DE', { currency: 'EUR', style: 'currency' })}</strong></p>
              <p className='text-muted' style={{ fontSize: '70%' }}>{product.description}</p>
              <span className='btn btn-primary btn-lg'>
              {selectedProduct === product ? 'Ausgewählt' : 'Streckenmaut auswählen'}
              </span>
            </div>
          </label>
        </div>
      ))}
{!selectedRoute && (
  <div className="from-label" style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
    Sie haben sich noch nicht für eine streckenmaut entschieden!
     
  </div>
)}

<Box style={{ textAlign: 'center' }}>
{selectedProduct && selectedProduct.id !== 3 && (
  <label className="lakezd">
    Gültig ab:
    <div className="wrapper-tijden" style={{ fontSize: '80%' }}>
      <span className="Geldig-tekst">Gültigkeit</span>
      <span className="naar-tekst notranslate">{formatDate(selectedDate)}</span>
      <span className="tot-tekst">bis</span>
      <span className="tot-tijd-tekst notranslate">{calculateValidityEndDate(selectedDate, selectedProduct)}</span>
      <span className="tot-time-tekst notranslate">23:59:59</span>
    </div>
  </label>
)}
</Box>
    {!selectedProduct && (
      <div className="from-label" style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>Sie haben sich noch nicht für eine Dauer entschieden!  </div>

    )}
</Box>

 
  </Box>

  <Box className='wrap-step-line' style={{ textAlign: 'center' }}>
    <img className='step-line' src={"/images/6106704c83dcd656e34be5a3_line_right.svg"} />
  </Box>


  <Box className='rounded-3 bg-light border input-py' style={{ marginBottom: "0", marginTop: "0", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  <Badge style={{ position: 'absolute', left: '-15px', top: '-15px',  background: '#10b981 ', color: 'rgb(255, 255, 255)', padding:'5px 15px', boxShadow: 'hsl(329deg 100% 50% / 50%) 0px 10px 40px -10px' }} className="rounded-3" >Schritt 2</Badge>

    <h2 className='display-7 w700' style={{ textAlign: 'center'}} >Kennzeichen fahrzeug</h2>

    <label className='lakezd' style={{ display: 'flex', flexDirection: 'column' }}>
      Zulassungsstaat des Kennzeichens
      <select style={{ paddingTop: '0', paddingBottom: '0' }} className='border rounded-3 form-select form-select-lg mb-3' value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
        <option value="" disabled>Bitte auswählen</option>
        {CountryCode.map((country) => (
          <option
            key={country.value}
            value={country.value}
             data-placeholdertext={country['data-placeholdertext']}
          >
            {country.label}
          </option>
        ))}
      </select>
    </label>
    <p className='no-margin'><mark>{CountryCode.find((country) => country.value === countryCode)?.contentValue}</mark></p>

    <Box className='wrap-kenz'>
  <label className='lakezd'>
    Kennzeichen fahrzeug:
    <div className='relative-div'>
      <div className={`country-code ${getCountryCodeColor(countryCode)}`}>
        {CountryCode.find(code => code.value === countryCode)?.licenseValue}
      </div>
      <input
        className='border rounded-3 form-control form-control-lg uppercase'
        type="text"
        value={licensePlate}
        onChange={handleLicensePlateChange}
        placeholder={CountryCode.find((country) => country.value === countryCode)?.['data-placeholdertext']}
      />
    </div>
  </label>
  <label className='lakezd'>
    Kennzeichen fahrzeug wiederholen:
    <div className='relative-div'>
      <div className={`country-code ${getCountryCodeColor(countryCode)}`}>
        {CountryCode.find(code => code.value === countryCode)?.licenseValue}
      </div>
      <input
        className='border rounded-3 form-control form-control-lg uppercase'
        type="text"
        value={confirmLicensePlate}
        onChange={handleConfirmLicensePlateChange}
        placeholder={CountryCode.find((country) => country.value === countryCode)?.['data-placeholdertext']}
      />
    </div>
  </label>
</Box>
    {!licensePlateMatch && <p style={{ color: 'red' }}>Kennzeichen stimmen nicht überein.</p>}
  </Box>

  <Box className='wrap-step-line' style={{ textAlign: 'center' }}>
    <img className='step-line' src={"/images/6106704c6dcf0ec5f5cb9acc_line_left.svg"} />
  </Box>

  <Box className='rounded-3 bg-light border' style={{ marginBottom: "0", marginTop: "0", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
    
  <Badge style={{ position: 'absolute', left: '-15px', top: '-15px',  background: '#10b981 ', color: 'rgb(255, 255, 255)', padding:'5px 15px', boxShadow: 'hsl(329deg 100% 50% / 50%) 0px 10px 40px -10px' }} className="rounded-3" >Schritt 3</Badge>
<h2 className='display-7 w700' style={{ textAlign: 'center'}} >Wohin dürfen wir den QR-Code und die Bestätigung senden?</h2>

    <Box className='wrap-kenz'>
      <label className='lakezd'>
        Vorname:
        <input className='border rounded-3 form-control form-control-lg' type="text" id="nameInput" />
        
      </label>
      <label className='lakezd'>
        Email:
        <input
          className='border rounded-3 form-control form-control-lg'
          type="email"
          id="emailInput"
          value={email}
          onChange={handleEmailChange}
        />
      </label>
      <label className='lakezd'>
        Email bestätigen:
        <input
          className='border rounded-3 form-control form-control-lg'
          type="email"
          id="confirmEmailInput"
          value={confirmEmail}
          onChange={handleConfirmEmailChange}
        />
      </label>
    </Box>
  </Box>


  <Box className='wrap-step-line' style={{ textAlign: 'center' }}>
    <img className='step-line' src={"/images/6106704c1072875c69f76536_line_middle-from-left.svg"} />
  </Box>

  <Box className='rounded-3 bg-light border' style={{ marginBottom: "2rem", marginTop: "0", position: 'relative', alignItems: "center", display: "flex", gap: "1rem", flexDirection: "column", marginLeft: "auto", marginRight: "auto", padding: "3rem 1rem" }}>
  <Badge style={{ position: 'absolute', left: '-15px', top: '-15px',  background: '#10b981 ', color: 'rgb(255, 255, 255)', padding:'5px 15px', boxShadow: 'hsl(329deg 100% 50% / 50%) 0px 10px 40px -10px' }} className="rounded-3" >Letzter Schritt</Badge>

    <h2 className='display-7 w700' style={{ textAlign: 'center'}} >Ausgewähltes Produkt</h2>
<Box className='row g-2 gy-4' style={{ width: '100%' }}>


    <Box className="col-12 col-sm-12 col-md-12 col-lg-6">
    <div>
  {selectedVehicle && (
    <>
      <p><strong>Streckenmaut:</strong></p>
      <p className='Geldig-tekst'>{selectedVehicle}</p>
    </>
  )}
  
  {licensePlate && countryCode && (
    <div>
      {licensePlate && (
        <>
          <p><strong>Kennzeichen Fahrzeug:</strong></p> 
          <p className='Geldig-tekst uppercase notranslate '>{licensePlate}</p>
        </>
      )}
      {CountryCode.find(country => country.value === countryCode)?.label && (
        <>
          <p><strong>Zulassungsstaat des Kennzeichens:</strong></p>
          <p className='Geldig-tekst uppercase'>{CountryCode.find(country => country.value === countryCode)?.label}</p>
        </>
      )}
    </div>
  )}

  {selectedProductName && (
    <>
      <p><strong>Ausgewähltes Produkt:</strong></p>
      <p className='Geldig-tekst'>{selectedProductName}</p>
    </>
  )}

  {!selectedProduct && (
    <div className="from-label" style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'baseline' }}>
      {selectedProduct && <p><strong>Gültigkeitszeitraum:</strong></p>}
      <p>Sie haben sich noch nicht für eine streckenmaut entschieden!</p>
      <a href='#products' className='p-primary'>Wählen sie streckenmaut  <FontAwesomeIcon icon={faChevronUp} /></a>
    </div>
  )}

  {selectedDate && selectedProduct && (
    <div className="wrapper-tijden">
      <p><strong>Gültigkeitszeitraum:</strong></p>
      <span className="Geldig-tekst">Gültigkeit</span>
      <span className="naar-tekst notranslate">{formatDate(selectedDate)}</span>
      <span className="tot-tekst">bis</span>
      <span className="tot-tijd-tekst notranslate">{calculateValidityEndDate(selectedDate, selectedProduct)}</span>
      <span className="tot-time-tekst notranslate">23:59:59</span>
    </div>
  )}
</div>

    </Box>

<Box id="checkout--end" className="col-12 col-sm-12 col-md-12 col-lg-6 border" style={{ textAlign: 'center', background: '#ffffff', padding: '15px', borderRadius: '15px', height: 'fit-content' }}>
    <div>
 <label className='lakezd'>Gesamtbetrag:</label>
 
      <Box className='display-5 w700 notranslate' style={{ textAlign: 'center'}} >{totalPrice.toFixed(2)} €</Box>
      <label className='text-muted Geldig-tekst' style={{ marginBottom: '1rem', fontSize: '80%' }}>inkl. 6 € Servicegebühr</label>
</div>

<label style={{ display: 'flex', gap: '10px', marginBottom: '1rem', alignItems: 'baseline', fontWeight: '800', textAlign: 'left', justifyContent: 'center' }}>
      <input className='border rounded-3' type="checkbox" id="shippingbox" checked={expressShipping} onChange={handleExpressShippingChange} />
      1-Stunden verarbeitung (+4,95€)
    </label>

    <button className='btn btn-primary btn-lg btn-checkout' onClick={handleFormSubmit} disabled={isLoading}>
      {isLoading ? 'Laden...' : 'Jetzt bestellen'} <FontAwesomeIcon icon={faLock} />
    </button>

    <div aria-live="polite" aria-atomic="true" className="position-relative">
    <div className="toast-container position-absolute top-0 end-0 p-3">
      {formErrors.map((error, index) => (
        <Toast key={index} show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Header closeButton={true} className="bg-danger text-white">
          <Toast.Body style={{ textAlign: 'left' }}>{error}</Toast.Body>
          </Toast.Header>
        </Toast>
      ))}
    </div>
  </div>
    {inputError && <p style={{ color: 'red' }}>Bitte füllen Sie alle Felder aus.</p>}
 
  </Box>


</Box>
</Box>
 
</Box>


  );
}


export async function getServerSideProps() {
  return {
    props: {}, 
  };
}


export default ProductListMaut;
